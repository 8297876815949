import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Logo from '@/components/svg/Logo.vue'
import LogoAdaptify from '@/components/svg/Adaptify.vue'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      logo: {
        component: Logo
      },
      logoAdaptify: {
        component: LogoAdaptify
      }
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#1560bd',
        secondary: '#ffa500',
        black: '#11111f',
        accent: '#ABEBC6',
        error: '#D35400',
        white: '#FFF',
        greydark: '#333333',
        'secondary-light': '#f8903d'
      }
    }
  }
});
