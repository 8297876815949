<template>
  <svg xmlns="http://www.w3.org/2000/svg"
     width="131.922mm" height="121.693mm"
     viewBox="0 0 374 345">
  <path id="Selection"
        stroke="black" stroke-width="1"
        d="M 184.00,21.00
           C 184.00,21.00 204.40,54.00 204.40,54.00
             204.40,54.00 244.45,121.00 244.45,121.00
             244.45,121.00 332.45,267.00 332.45,267.00
             332.45,267.00 367.00,324.00 367.00,324.00
             367.00,324.00 200.00,324.00 200.00,324.00
             179.16,323.97 159.75,314.73 144.00,301.57
             139.98,298.20 132.52,290.92 129.19,287.00
             127.96,285.55 125.49,282.65 126.45,280.57
             127.37,278.58 132.16,279.00 134.00,279.00
             134.00,279.00 270.00,279.00 270.00,279.00
             270.00,279.00 284.00,280.00 284.00,280.00
             284.00,280.00 257.80,235.00 257.80,235.00
             257.80,235.00 206.70,150.00 206.70,150.00
             206.70,150.00 186.00,115.00 186.00,115.00
             186.00,115.00 168.45,140.00 168.45,140.00
             157.63,158.29 152.31,181.18 158.44,202.00
             160.34,208.44 163.29,214.31 166.81,220.00
             170.43,225.84 178.09,235.21 183.01,239.91
             189.74,246.33 197.54,251.94 206.00,255.83
             210.46,257.88 212.94,257.32 217.00,260.00
             217.00,260.00 199.00,260.00 199.00,260.00
             185.60,259.84 171.09,256.43 159.00,250.68
             149.53,246.18 141.24,239.56 134.04,232.00
             111.35,208.20 107.38,173.41 117.05,143.00
             120.60,131.83 131.46,113.67 137.58,103.00
             137.58,103.00 166.85,51.00 166.85,51.00
             166.85,51.00 184.00,21.00 184.00,21.00 Z
           M 94.02,173.00
           C 94.69,176.26 93.91,179.64 94.02,183.00
             94.48,194.45 97.57,208.57 102.31,219.00
             109.22,234.22 122.92,246.37 128.00,256.00
             128.00,256.00 94.00,275.60 94.00,275.60
             94.00,275.60 39.00,308.40 39.00,308.40
             39.00,308.40 7.00,327.00 7.00,327.00
             7.00,327.00 15.38,311.00 15.38,311.00
             15.38,311.00 33.80,279.00 33.80,279.00
             33.80,279.00 70.42,215.00 70.42,215.00
             70.42,215.00 94.02,173.00 94.02,173.00 Z" />
</svg>
</template>

<script>
export default {
  name: 'logoAdaptify'
}
</script>
