<template>
<svg xmlns="http://www.w3.org/2000/svg"
     width="7.40278in" height="7.44444in"
     viewBox="0 0 533 536">
  <path id="Selection"
        stroke="black" stroke-width="1"
        d="M 208.00,42.00
           C 198.95,45.30 183.05,44.00 173.00,44.00
             173.00,44.00 106.00,44.00 106.00,44.00
             96.23,43.99 91.57,42.99 82.00,46.86
             60.81,55.45 41.04,74.84 41.00,99.00
             41.00,99.00 41.00,168.00 41.00,168.00
             41.00,168.00 41.00,215.00 41.00,215.00
             40.96,217.75 40.20,226.19 35.30,223.83
             32.58,222.53 33.01,217.51 33.00,215.00
             33.00,215.00 33.00,105.00 33.00,105.00
             33.01,99.12 33.94,89.52 35.75,84.00
             45.18,55.21 74.23,36.05 104.00,36.00
             104.00,36.00 183.00,36.00 183.00,36.00
             183.00,36.00 200.00,36.00 200.00,36.00
             201.82,36.01 204.87,35.86 206.37,36.98
             207.81,38.06 207.78,40.36 208.00,42.00 Z
           M 503.00,92.00
           C 502.61,82.27 492.82,69.29 485.96,62.83
             464.48,42.59 448.46,43.00 421.00,43.00
             421.00,43.00 386.00,43.00 386.00,43.00
             386.00,43.00 350.00,43.00 350.00,43.00
             347.54,42.96 340.75,42.79 342.62,38.39
             343.85,35.50 349.42,36.00 352.00,36.00
             352.00,36.00 418.00,36.00 418.00,36.00
             435.86,36.00 453.22,35.25 470.00,42.90
             475.47,45.39 481.37,49.27 486.00,53.08
             497.76,62.78 506.97,75.97 510.34,91.00
             513.11,103.38 512.00,126.43 512.00,140.00
             512.00,140.00 512.00,216.00 512.00,216.00
             512.00,217.85 512.12,221.21 511.40,222.85
             510.16,225.69 506.96,226.40 505.60,223.68
             504.87,222.23 505.00,218.69 505.00,217.00
             505.00,217.00 505.00,128.00 505.00,128.00
             505.00,128.00 505.00,104.00 505.00,104.00
             504.95,100.02 505.02,95.47 503.00,92.00 Z
           M 344.00,38.00
           C 344.00,38.00 343.00,38.00 343.00,38.00
             343.00,38.00 344.00,39.00 344.00,39.00
             344.00,39.00 344.00,38.00 344.00,38.00 Z
           M 129.00,118.00
           C 129.00,118.00 336.00,118.00 336.00,118.00
             367.92,118.05 400.60,142.64 413.69,171.00
             420.38,185.49 422.02,197.26 422.00,213.00
             421.97,232.81 412.16,254.06 399.56,269.00
             395.86,273.39 387.66,281.91 382.00,283.00
             382.00,283.00 382.00,285.00 382.00,285.00
             382.00,285.00 378.00,285.00 378.00,285.00
             378.00,285.00 379.00,287.00 379.00,287.00
             379.00,287.00 378.00,288.00 378.00,288.00
             378.00,288.00 376.00,287.00 376.00,287.00
             376.00,287.00 376.00,289.00 376.00,289.00
             376.00,289.00 374.00,288.00 374.00,288.00
             374.00,288.00 374.00,290.00 374.00,290.00
             370.15,290.22 368.68,291.32 366.00,294.00
             361.85,293.98 361.04,294.14 358.00,297.00
             355.79,296.57 354.96,295.80 354.00,298.00
             350.54,297.49 349.98,297.01 347.00,299.00
             336.37,297.09 309.30,298.00 296.86,298.00
             296.86,298.00 296.86,358.00 296.86,358.00
             297.00,359.99 297.08,363.14 296.86,365.00
             295.14,370.24 287.70,377.45 283.00,380.00
             282.08,384.89 275.99,391.06 272.00,394.00
             272.00,394.00 272.00,363.00 272.00,363.00
             272.00,363.00 272.73,341.00 272.73,341.00
             272.73,341.00 272.02,325.00 272.02,325.00
             272.02,325.00 272.99,308.00 272.99,308.00
             272.99,308.00 272.00,275.00 272.00,275.00
             272.00,275.00 319.00,275.00 319.00,275.00
             333.42,275.00 342.08,276.17 356.00,270.55
             362.48,267.93 369.79,263.46 375.00,258.82
             379.38,254.91 380.69,251.63 386.00,249.00
             386.53,243.58 389.78,240.83 392.19,236.00
             394.74,230.91 397.30,222.64 398.04,217.00
             398.53,214.29 398.06,211.72 398.04,209.00
             398.04,209.00 398.04,200.04 398.04,200.04
             396.52,186.65 388.28,172.42 378.99,163.00
             378.99,163.00 361.00,148.00 361.00,148.00
             361.00,148.00 338.00,141.91 338.00,141.91
             338.00,141.91 319.00,141.91 319.00,141.91
             319.00,141.91 274.00,141.91 274.00,141.91
             274.00,141.91 267.00,142.60 267.00,142.60
             267.00,142.60 245.00,163.00 245.00,163.00
             245.00,163.00 239.00,173.00 239.00,173.00
             239.00,173.00 204.85,211.54 204.85,211.54
             204.85,211.54 192.00,227.00 192.00,227.00
             192.00,227.00 175.72,244.58 175.72,244.58
             175.72,244.58 155.30,267.84 155.30,267.84
             155.30,267.84 143.00,283.00 143.00,283.00
             143.00,283.00 130.00,295.00 130.00,295.00
             130.00,295.00 129.00,298.00 129.00,298.00
             129.00,298.00 129.00,269.00 129.00,269.00
             129.02,258.16 131.79,258.90 138.71,251.00
             138.71,251.00 157.98,229.00 157.98,229.00
             157.98,229.00 208.28,171.00 208.28,171.00
             214.45,163.91 229.99,147.69 233.00,140.00
             233.00,140.00 223.00,140.00 223.00,140.00
             223.00,140.00 221.46,141.46 221.46,141.46
             221.46,141.46 199.00,142.00 199.00,142.00
             199.00,142.00 129.00,142.00 129.00,142.00
             129.00,142.00 129.00,118.00 129.00,118.00 Z
           M 222.00,140.00
           C 222.00,140.00 217.00,141.00 217.00,141.00
             217.00,141.00 222.00,140.00 222.00,140.00 Z
           M 129.00,220.00
           C 129.00,220.00 129.00,186.00 129.00,186.00
             129.00,183.40 128.50,175.58 129.99,173.74
             131.70,171.62 136.50,172.00 139.00,172.00
             144.74,171.99 158.80,170.43 162.40,175.23
             165.79,179.76 160.69,184.70 157.87,188.00
             152.25,194.58 134.48,215.73 129.00,220.00 Z
           M 272.00,172.00
           C 272.00,172.00 289.00,172.00 289.00,172.00
             289.00,172.00 325.00,172.00 325.00,172.00
             333.97,172.04 343.44,173.67 351.00,178.78
             373.19,193.80 373.48,222.34 352.91,238.96
             341.36,248.29 331.69,247.00 318.00,247.00
             318.00,247.00 272.00,247.00 272.00,247.00
             272.00,247.00 272.00,222.00 272.00,222.00
             272.00,222.00 327.00,222.00 327.00,222.00
             330.88,221.99 334.52,222.23 338.00,220.15
             346.33,215.15 348.56,205.78 339.96,199.70
             334.47,195.82 329.15,196.93 323.00,197.00
             323.00,197.00 291.00,197.00 291.00,197.00
             284.90,197.00 277.83,197.66 272.00,196.00
             272.00,196.00 272.00,172.00 272.00,172.00 Z
           M 161.00,174.00
           C 161.00,174.00 160.00,174.00 160.00,174.00
             160.00,174.00 161.00,175.00 161.00,175.00
             161.00,175.00 161.00,174.00 161.00,174.00 Z
           M 163.00,180.00
           C 163.00,180.00 162.00,180.00 162.00,180.00
             162.00,180.00 163.00,181.00 163.00,181.00
             163.00,181.00 163.00,180.00 163.00,180.00 Z
           M 242.00,208.00
           C 244.40,211.71 243.99,213.73 244.00,218.00
             244.00,218.00 244.00,251.00 244.00,251.00
             244.00,251.00 244.00,363.00 244.00,363.00
             244.00,363.00 232.74,349.98 232.74,349.98
             232.74,349.98 219.60,334.99 219.60,334.99
             219.60,334.99 219.00,328.00 219.00,328.00
             219.00,328.00 219.00,244.00 219.00,244.00
             219.00,244.00 219.74,236.00 219.74,236.00
             219.74,236.00 242.00,208.00 242.00,208.00 Z
           M 235.00,220.00
           C 235.00,220.00 234.00,220.00 234.00,220.00
             234.00,220.00 235.00,221.00 235.00,221.00
             235.00,221.00 235.00,220.00 235.00,220.00 Z
           M 385.00,281.00
           C 385.00,281.00 384.00,281.00 384.00,281.00
             384.00,281.00 385.00,282.00 385.00,282.00
             385.00,282.00 385.00,281.00 385.00,281.00 Z
           M 378.00,286.00
           C 378.00,286.00 377.00,286.00 377.00,286.00
             377.00,286.00 378.00,287.00 378.00,287.00
             378.00,287.00 378.00,286.00 378.00,286.00 Z
           M 79.00,508.00
           C 84.35,507.92 92.93,511.98 105.00,512.00
             105.00,512.00 198.00,512.00 198.00,512.00
             199.83,512.00 203.23,511.88 204.85,512.60
             207.85,513.93 208.04,517.24 205.57,518.98
             203.80,520.22 200.12,520.00 198.00,520.00
             198.00,520.00 128.00,520.00 128.00,520.00
             107.49,520.00 88.82,520.72 70.00,510.68
             65.15,508.09 59.02,503.64 55.00,499.91
             46.43,491.96 38.78,481.33 35.44,470.00
             31.60,456.99 33.00,431.48 33.08,417.00
             33.08,417.00 33.08,327.00 33.08,327.00
             33.08,327.00 33.08,321.02 33.08,321.02
             34.18,315.97 37.93,315.52 39.83,318.32
             41.22,320.38 41.00,324.55 40.98,327.00
             40.98,327.00 40.98,455.00 40.98,455.00
             40.98,455.00 40.98,459.91 40.98,459.91
             40.69,472.78 52.64,487.51 61.58,495.78
             65.81,499.69 73.11,505.83 79.00,506.00
             79.00,506.00 79.00,508.00 79.00,508.00 Z
           M 467.00,506.00
           C 477.48,504.30 492.63,486.81 497.56,478.00
             506.79,461.52 505.00,449.91 505.00,432.00
             505.00,432.00 505.00,371.00 505.00,371.00
             505.00,371.00 505.00,332.00 505.00,332.00
             505.05,329.60 505.52,323.12 509.70,325.17
             512.42,326.49 511.99,331.47 511.94,334.00
             511.94,334.00 511.94,413.00 511.94,413.00
             511.94,413.00 511.94,456.17 511.94,456.17
             511.71,460.12 510.68,462.29 509.64,466.00
             503.58,487.62 487.97,506.08 467.00,514.55
             452.80,520.28 437.05,520.00 422.00,520.00
             422.00,520.00 384.00,520.00 384.00,520.00
             384.00,520.00 373.00,519.34 373.00,519.34
             373.00,519.34 364.00,520.00 364.00,520.00
             360.55,520.04 347.64,520.41 345.23,519.26
             342.48,517.96 341.95,514.79 344.45,513.02
             346.19,511.78 349.89,512.00 352.00,512.00
             352.00,512.00 444.00,512.00 444.00,512.00
             444.00,512.00 465.00,509.00 465.00,509.00
             465.00,509.00 465.00,507.00 465.00,507.00
             465.00,507.00 467.00,508.00 467.00,508.00
             467.00,508.00 467.00,506.00 467.00,506.00 Z
           M 286.00,375.00
           C 286.00,375.00 285.00,375.00 285.00,375.00
             285.00,375.00 286.00,376.00 286.00,376.00
             286.00,376.00 286.00,375.00 286.00,375.00 Z
           M 277.00,384.00
           C 277.00,384.00 276.00,384.00 276.00,384.00
             276.00,384.00 277.00,385.00 277.00,385.00
             277.00,385.00 277.00,384.00 277.00,384.00 Z
           M 276.00,385.00
           C 276.00,385.00 275.00,385.00 275.00,385.00
             275.00,385.00 276.00,386.00 276.00,386.00
             276.00,386.00 276.00,385.00 276.00,385.00 Z
           M 48.00,486.00
           C 48.00,486.00 47.00,486.00 47.00,486.00
             47.00,486.00 48.00,487.00 48.00,487.00
             48.00,487.00 48.00,486.00 48.00,486.00 Z
           M 52.00,490.00
           C 52.00,490.00 51.00,490.00 51.00,490.00
             51.00,490.00 52.00,491.00 52.00,491.00
             52.00,491.00 52.00,490.00 52.00,490.00 Z
           M 53.00,491.00
           C 53.00,491.00 52.00,491.00 52.00,491.00
             52.00,491.00 53.00,492.00 53.00,492.00
             53.00,492.00 53.00,491.00 53.00,491.00 Z
           M 56.00,494.00
           C 56.00,494.00 55.00,494.00 55.00,494.00
             55.00,494.00 56.00,495.00 56.00,495.00
             56.00,495.00 56.00,494.00 56.00,494.00 Z" />
</svg>

</template>

<script>
export default {
  name: 'logo'
}
</script>
