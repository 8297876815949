<template>
  <v-app>
    <!-- Header con imagen de fondo y texto -->
    <v-container
      dark
      fluid
      class="primary"
      height="100"
    >
      <v-row>
        <v-col cols="12" md="10" class="ma-auto">
          <v-row>
            <v-col class="d-flex flex-row">
              <v-icon size="76" class="logo">$logoAdaptify</v-icon>
              <span class="text-h2 white--text pl-4 brand d-none d-sm-block">AdaptifyX</span>
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <a href="#services" style="text-decoration: none"><span class="text-h5 secondary--text pr-10"> Servicios </span></a>
              <a href="#faq" style="text-decoration: none"><span class="text-h5 secondary--text pr-10"> Faq </span></a>
              <a href="#contact" style="text-decoration: none"><span class="text-h5 secondary--text"> Contacto </span></a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Sección de About -->
    <v-container
      dark
      fluid
      class="primary"
      height="650"
    >
      <v-row>
        <v-col cols="12" md="10" class="ma-auto">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-col cols="12" md="5">
              <div class="d-flex flex-column justify-center" transition="scroll-x-transition">
                <h6 class="text-h6 font-italic mb-2 white--text slogan">Digitaliza tus procesos y simplifica tu vida</h6>
                <h1 class="text-h1 mb-10 white--text font-weight-bold" style="line-height: 4rem;">Deja atrás <br>los procesos manuales y <br>únete a la era digital</h1>
                <p class="text-subtitle-1 white--text" style="line-height: 1.5rem;">Bienvenido a la era digital, donde todo está al alcance de un clic. En un mundo cada vez más conectado, ¿por qué seguir confiando en procesos manuales para llevar a cabo tus tareas diarias? Es hora de dejar atrás los métodos obsoletos y unirte a la revolución digital.</p>
              </div>
            </v-col>
            <v-col cols="12" md="7">
              <v-img
                class="ma-auto rounded-lg"
                alt="Optimizacion de procesos"
                lazy-src="https://4pertinax-resources.s3.amazonaws.com/landing-2_tn.jpg"
                max-height="550"
                max-width="550"
                src="https://4pertinax-resources.s3.amazonaws.com/landing-2.png"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      dark
      fluid
      class="primary py-10"
      height="600"
    >
      <v-row>
        <v-col cols="12" md="10" class="ma-auto py-10">
          <v-row class="my-5">
            <v-col>
              <v-card class="elevation-0 primary">
                <v-row class="ma-auto pb-10">
                  <v-col cols="12" md="6">
                    <v-img
                      class="ma-auto rounded"
                      contain
                      alt="digitalizacion de procesos"
                      lazy-src="https://4pertinax-resources.s3.amazonaws.com/objetivo_tn.jpg"
                      max-height="400"
                      max-width="350"
                      src="https://4pertinax-resources.s3.amazonaws.com/objetivo.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex flex-column py-4">
                    <span class="text-h6 white--text mb-4 slogan">Objetivo</span>
                    <p class="text-h5 grey--text text--lighten-2"> Nuestro objetivo es ayudar a nuestros clientes a digitalizar sus procesos y simplificar su vida. Ya no tendrás que preocuparte por la tediosa tarea de recolectar información manualmente o por errores humanos que retrasan el proceso. Con nuestros servicios, podrás acceder a tus datos en tiempo real, lo que te permitirá tomar decisiones informadas y eficientes.</p>
                  </v-col>
                </v-row>
             <!--  </v-card>
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col>
              <v-card class="primary"> -->
                <v-row class="ma-auto pt-10">
                  <v-col cols="12" md="6" class="d-flex flex-column py-4">
                    <span class="text-h6 white--text mb-4 slogan">Tiempos y recursos</span>
                    <p class="text-h5 grey--text text--lighten-2"> Imagina cuánto tiempo y recursos podrías ahorrar si pudieras automatizar tus procesos. No tendrás que dedicar horas a ingresar información en hojas de cálculo o a hacer seguimiento a correos electrónicos. En cambio, podrás enfocarte en tareas más importantes y estratégicas para tu empresa.</p>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-img
                      class="ma-auto rounded"
                      alt="automatizacion de procesos"
                      contain
                      lazy-src="https://4pertinax-resources.s3.amazonaws.com/tiempo-recurso_tn.jpg"
                      max-height="400"
                      max-width="350"
                      src="https://4pertinax-resources.s3.amazonaws.com/tiempo-recurso.png"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Sección de Servicios -->
    <v-container
      dark
      fluid
      class="greydark py-16"
      height="500"
      id="services"
    >
      <v-row>
        <v-col cols="12" md="10" class="ma-auto">

          <v-row>
            <v-col cols="12" class="text-center pb-0">
              <span class="text-subtitle-2 white--text slogan-primary">CARACTERÍSTICAS</span>
            </v-col>
            <v-col cols="12" class="text-center pt-0">
              <span class="text-h2 white--text">¿Para qué te registras?</span>
            </v-col>
          </v-row>

          <v-row class="pb-10">
            <v-col cols="12" md="4" class="d-flex flex-column justify-center">
              <v-expansion-panels>
                <v-expansion-panel class="secondary-light my-1 px-5">
                  <v-expansion-panel-header hide-actions class="white--text py-6" style="line-height: 1.5rem;" @click="service = true">
                    Encuentra el servicio de Management que se adecue a tus necesidades
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="greydark--text text-body-2">
                    puedes digitalizar tus solicitudes y administrarlas de manera eficiente, sin perderle el rastro a alguna solicitud. Además, puedes saber en todo momento en qué etapa se encuentra una solicitud y quiénes son los usuarios involucrados en ella.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="secondary-light my-1 px-5">
                  <v-expansion-panel-header hide-actions class="white--text py-6" style="line-height: 1.5rem;" @click="service = false">
                    Configura los formularios que desees!!!
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="greydark--text text-body-2">
                    puedes configurar la cantidad de formularios que necesites para tu operacion sin limite. aumenta tu alcance y ten felices a tus clientes con el seguimiento de sus gestiones.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

            <v-col cols="12" md="8">
              <v-img
                v-if="service"
                class="ma-auto rounded"
                alt="encuentra el servicio"
                contain
                lazy-src="https://4pertinax-resources.s3.amazonaws.com/893bfaa6-04c2-435c-b66e-e98f548368fb_tn.jpg"
                max-height="600"
                max-width="550"
                src="https://4pertinax-resources.s3.amazonaws.com/893bfaa6-04c2-435c-b66e-e98f548368fb.png"
              ></v-img>
              <v-img
                v-else
                class="ma-auto rounded"
                alt="encuentra el servicio"
                contain
                lazy-src="https://4pertinax-resources.s3.amazonaws.com/dbc22306-69a6-4576-8cec-5b3b33008555_tn.jpg"
                max-height="600"
                max-width="550"
                src="https://4pertinax-resources.s3.amazonaws.com/dbc22306-69a6-4576-8cec-5b3b33008555.png"
              ></v-img>
            </v-col>
          </v-row>

          <v-row
            class="fill-height pt-10"
            align="center"
            justify="center"
          >
            <v-col>
              <v-row>
                <v-col>
                  <v-card style="min-height: 500px">
                    <v-row class="ma-auto">
                      <v-col class="text-center">
                        <v-img
                          class="ma-auto rounded my-3"
                          contain
                          lazy-src="https://4pertinax-resources.s3.amazonaws.com/digitalization_tn.jpg"
                          max-height="125"
                          alt="digitalizacion"
                          max-width="120"
                          src="https://4pertinax-resources.s3.amazonaws.com/digitalization.png"
                        ></v-img>
                        <span class="primary--text text-subtitle-1">Digitalizacion</span>
                      </v-col>
                    </v-row>
                    <v-row class="ma-auto">
                      <v-col>
                        <span class="grey--text text-body-2">La digitalización permite que todas las tareas y procesos se realicen de manera más rápida y eficiente. Con un sistema de gestión digital, sus gestores pueden realizar sus tareas y compartir información de manera instantánea, eliminando la necesidad de esperar a que se realicen trámites manuales que pueden llevar horas o incluso días.</span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col>
                  <v-card style="min-height: 500px">
                    <v-row class="ma-auto">
                      <v-col class="text-center">
                        <v-img
                          class="ma-auto rounded my-3"
                          contain
                          lazy-src="https://4pertinax-resources.s3.amazonaws.com/facility_tn.jpg"
                          max-height="125"
                          max-width="250"
                          alt="Facilidad"
                          src="https://4pertinax-resources.s3.amazonaws.com/facility.jpg"
                        ></v-img>
                        <span class="primary--text text-subtitle-1">Facilidad de uso</span>
                      </v-col>
                    </v-row>
                    <v-row class="ma-auto">
                      <v-col>
                        <span class="grey--text text-body-2">La facilidad de uso es una de las principales ventajas de un sistema de gestión digital. Este tipo de software está diseñado para ser intuitivo y fácil de usar, lo que significa que cualquier gestor/usuario, independientemente de su nivel de habilidad tecnológica, podrá utilizarlo sin problemas.</span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col>
                  <v-card style="min-height: 500px">
                    <v-row class="ma-auto">
                      <v-col class="text-center">
                        <v-img
                          class="ma-auto rounded my-3"
                          contain
                          lazy-src="https://4pertinax-resources.s3.amazonaws.com/integrate_tn.jpg"
                          max-height="125"
                          alt="integracion"
                          max-width="250"
                          src="https://4pertinax-resources.s3.amazonaws.com/integrate.jpg"
                        ></v-img>
                        <span class="primary--text text-subtitle-1">Integracion</span>
                      </v-col>
                    </v-row>
                    <v-row class="ma-auto">
                      <v-col>
                        <span class="grey--text text-body-2">Un sistema de gestión digital integra todas las áreas y procesos de su empresa en un solo lugar, lo que significa que todos los datos y la información se pueden acceder y utilizar de manera más eficiente. Esto elimina la necesidad de usar múltiples sistemas o herramientas diferentes para realizar diferentes tareas y procesos.</span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col>
                  <v-card style="min-height: 500px">
                    <v-row class="ma-auto">
                      <v-col class="text-center">
                        <v-img
                          class="ma-auto rounded my-3"
                          contain
                          lazy-src="https://4pertinax-resources.s3.amazonaws.com/follow-up_tn.jpg"
                          max-height="125"
                          alt="seguimiento"
                          max-width="250"
                          src="https://4pertinax-resources.s3.amazonaws.com/follow-up.png"
                        ></v-img>
                        <span class="primary--text text-subtitle-1">Seguimiento</span>
                      </v-col>
                    </v-row>
                    <v-row class="ma-auto">
                      <v-col>
                        <span class="grey--text text-body-2">El seguimiento tanto del usuario beneficiario como de la empresa que gestiona es una característica clave de un sistema de gestión digital. Esto significa que podrá realizar un seguimiento de todas las actividades que se llevan a cabo dentro de la solicitud, lo que le permitirá identificar áreas de mejora y tomar decisiones informadas para mejorar la eficiencia y la productividad.</span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
               <!--  <v-col
                  cols="12"
                  md="6"
                  class="mb-5"
                >
                  <v-card
                    class="elevation-10"
                    height="100%"
                  >
                    <v-card-title>Condominium <span class="font-italic px-4 grey--text text-body-2">En construccion</span></v-card-title>
                    <v-card-text>
                      <span class="font-weight-bold">¿Tienes problemas para administrar tus propiedades?</span>
                      ¡No te preocupes más! Con nuestro servicio de Condominium, puedes administrar condominios o proyectos
                      que cuenten con varias propiedades de manera fácil y sencilla. Nuestro sistema te ayudará con la
                      comunicación, los pagos y el seguimiento de iniciativas de los proyectos, todo en un solo lugar.
                    </v-card-text>
                  </v-card>
                </v-col> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container dark fluid class="white py-16" id="faq">
      <v-row>
        <v-col cols="12" class="text-center pb-0">
          <span class="text-subtitle-2 greydark--text slogan-primary">FAQ</span>
        </v-col>
        <v-col cols="12" class="text-center pt-0">
          <span class="text-h2 greydark--text">¿Tienes dudas?</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8" class="ma-auto">
          <v-expansion-panels>
            <v-expansion-panel class="primary my-1 px-5">
              <v-expansion-panel-header hide-actions class="white--text py-6 text-h5" style="line-height: 1.5rem;">
                Cuantos formularios puedo crear?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="white--text text--darken-1 text-h6">
                La cantidad de formularios no tiene limite, puedes tener la cantidad de formularios que necesites.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="primary my-1 px-5">
              <v-expansion-panel-header hide-actions class="white--text py-6 text-h5" style="line-height: 1.5rem;">
                Puedo separar mis tipos de formularios?
              </v-expansion-panel-header>
              <v-expansion-panel-content class="white--text text--darken-1 text-h6">
                Si se tiene un concepto de owner o grupo en el cual se pueden agregar formularios y ahi se pueden agrupar por tipos de formulario o contexto de negocio.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <!-- Sección de Contacto -->
    <v-container class="contact-section" id="contact">
      <v-row>
        <v-col class="text-center">
          <h2 class="section-title">Contactanos</h2>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="6" class="d-flex flex-column align-center justify-center" style="position: relative;">
           <v-img
            :style="quoteStyle"
            class="rounded"
            src="https://4pertinax-resources.s3.amazonaws.com/quote.png"
          ></v-img>
          <span class="text-h2 pb-5 text-center">Queremos saber de ti!</span>
          <p class="text-body-1">contactanos para conocer mas de como se adaptan los servicios a tu operacion diaria y poder facilitarte la vida.</p>
        </v-col>
        <v-col cols="12" md="6">
          <v-form class="contact-form grey lighten-5 rounded pa-10" @submit.prevent="submitForm">
            <v-text-field
              v-model="form.name"
              label="Nombre completo"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.email"
              label="Correo electrónico"
              type="email"
              :rules="[
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
              ]"
              required
            ></v-text-field>
            <v-text-field v-model="form.subject" label="Asunto" required></v-text-field>
            <v-textarea
              v-model="form.message"
              label="Mensaje"
              required
            ></v-textarea>
            <v-btn type="submit" color="primary">Enviar</v-btn>
          </v-form>
        </v-col>

      </v-row>
    </v-container>
  </v-app>

</template>

<script>
import axios from 'axios'
import URI from 'urijs'

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      service: true
    };
  },
  methods: {
    submitForm() {
      // Aquí iría la lógica para enviar el formulario
      const uri = new URI('https://contact.4pertinax.com')
      uri.segment('items')

      const body = {name: this.form.name, email: this.form.email, subject: this.form.subject, message: this.form.message }
      axios.put(uri.toString(), body, {
        headers: {
            'Content-Type': 'application/json',
        }})
      this.form = {
        name: "",
        email: "",
        subject: "",
        message: "",
      };
    },
    tryRequestWeb() {
      window.open('https://trackease-demo.adaptifyx.com', '_blank')
    }
  },
  computed: {
    quoteStyle() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'max-height: 150px;width: 150px;position: absolute;top: 80px;opacity: .6;right: 44px;'
      } else {
        return 'max-height: 100px;width: 100px;position: absolute;top: -50px;opacity: .6;right: 44px;'
      }
    }
  }
};
</script>

<style lang="scss">

.logo > svg {
  fill: white;
  filter: drop-shadow(3px 3px 9px #0b2f5b);
}

.brand {
  text-shadow: 3px 3px 9px #0b2f5b;
}

.slogan::before {
  position: relative;
  bottom: -0.1em;
  content: '';
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  background-color: var(--v-secondary-base);
  line-height: 0;
  margin-right: 1em;
}

.slogan-primary::before {
  position: relative;
  bottom: -0.1em;
  content: '';
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  background-color: var(--v-primary-base);
  line-height: 0;
  margin-right: 1em;
}
</style>
